export const appBuildAssetsDir = "/_nuxt/1730724647719/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":null},{"name":"keywords","content":null},{},{"property":"og:title","content":"Strive Sports Network"},{"property":"og:type","content":"article"},{"property":"og:image","content":"https://strive.watch.pixellot.tv/images/customer/logo.png"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"property":"og:description","content":""},{"property":"og:site_name","content":"Strive Sports Network"},{"property":"og:url","content":"https://strive.watch.pixellot.tv"},{"property":"article:published_time","content":"11/4/2024"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":null},{"name":"twitter:title","content":"Strive Sports Network"},{"name":"twitter:description","content":""},{"name":"twitter:image:src","content":"https://strive.watch.pixellot.tv/images/customer/logo.png"},{"name":"twitter:image:alt","content":"/images/customer/logo.png image"},{"name":"apple-itunes-app","content":"app-id=6651856158, app-argument=https://strive.watch.pixellot.tv"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/images/customer/favicon.png"}],"style":[{"children":"\n              :root {\n                --base-customer-color: #D4AD29;\n                --base-primary-accent-color: #D4AD29;\n              }\n            ","type":"text/css"}],"script":[{"id":"imaSDK","type":"text/javascript","src":"https://imasdk.googleapis.com/js/sdkloader/ima3.js"},{"type":"text/javascript","src":"https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"}],"noscript":[],"title":"Strive Sports Network"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false